<template>
  <div
    id="webauthn-wrapper"
    class="row"
  >
    <div
      id="fingerprint-wrapper"
      class="col-4"
    >
      <svg-fingerprint
        :animate-success="animateSuccess"
        style="position: absolute;"
      />
    </div>
    <div class="col-8 container">
      <div class="row">
        <h3>{{ $t("webauthn.title") }}</h3>
      </div>
      <div class="row">
        <p>{{ $t("webauthn.continue") }}</p>
      </div>
      <div
        v-if="!hideAlternativeProcess"
        class="row"
      >
        <a
          @click.prevent="$emit('alternative-process')"
          href="javascript:;"
          class="theme-color"
        >{{ alternativeProcessTitle }}</a>
      </div>
    </div>
    <div
      id="webauthn-button"
      class="col-12"
    >
      <ws-button
        :disabled="!supportsWebauthn"
        @click="$emit('authenticate-clicked')"
        class="theme-bg uppercase"
        size="lg"
        variant="primary"
      >
        <span>{{ $t('webauthn.authenticate') }}</span>
      </ws-button>
    </div>
    <div
      id="webauthn-not-supported"
      v-if="!supportsWebauthn"
      class="col-12"
    >
      <p>{{ webauthnNotFoundMessage }}</p>
    </div>
  </div>
</template>

<script>
import SvgFingerprint from "client/app/components/svg/SvgFingerprint";
import webauthnService from "client/app/services/webauthn";
import { WsButton } from "WS_UIkit";

export default {
  name: "Webauthn",
  components: {
    SvgFingerprint,
    WsButton,
  },
  props: {
    animateSuccess: {
      type: Boolean,
      default: false,
    },
    hideAlternativeProcess: {
      type: Boolean,
      default: false,
    },
    alternativeProcessTitle: {
      type: String,
      default: "",
    },
    tryAgainTitle: {
      type: String,
      default: "",
    },
    authType: {
      type: String,
      default: "",
    },
  },
  computed: {
    webauthnNotFoundMessage() {
      switch (this.authType) {
        case webauthnService.PASSWORDLESS:
          return this.$t('webauthn.not_supported');
        case webauthnService.TWO_FACTOR:
          return this.$t('webauthn2fa.not_supported');
        default:
          return '';
      }
    },
    supportsWebauthn() {
      return webauthnService.supports();
    },
  },
  mounted() {
    this.$emit('mounted');
  },
}
</script>

<style lang="scss" scoped>
#webauthn-wrapper {
  margin-top: 2rem;

  a.disabled {
    color: $text-color-light;
    cursor: not-allowed;
    text-decoration: none;
  }

  ::v-deep #webauthn-button {
    margin-top: 25px;
    text-align: right;

    @media (max-width: 400px) {
      text-align: center;

      button {
        width: 100%;
      }
    }
  }

  #webauthn-not-supported {
    padding-top: 15px;
    color: $red;
  }

  #fingerprint-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
